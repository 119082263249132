import ApplePayLogo from '@assets/payment-logos/apple-pay.svg';
import KlarnaLogo from '@assets/payment-logos/klarna.svg';
import MastercardLogo from '@assets/payment-logos/mastercard.svg';
import GiropayLogo from '@assets/payment-logos/giropay.svg';
import PaypalLogo from '@assets/payment-logos/paypal.svg';
import SEPALogo from '@assets/payment-logos/sepa.svg';
import SofortLogo from '@assets/payment-logos/sofort.svg';
import VisaLogo from '@assets/payment-logos/visa.svg';
import ApplePayLogoOriginal from '@assets/payment-logos/apple-pay-original.svg';
import KlarnaLogoOriginal from '@assets/payment-logos/klarna-original.svg';
import MastercardLogoOriginal from '@assets/payment-logos/mastardcard-original.svg';
import GiropayLogoOriginal from '@assets/payment-logos/giropay-original.svg';
import PaypalLogoOriginal from '@assets/payment-logos/paypal-original.svg';
import SEPALogoOriginal from '@assets/payment-logos/sepa-original.svg';
import SofortLogoOriginal from '@assets/payment-logos/sofort-original.svg';
import VisaLogoOriginal from '@assets/payment-logos/visa-original.svg';

export const paymentMethods = [
    {
        id: 8,
        name: 'Visa',
        logo: VisaLogo,
        originalLogo: VisaLogoOriginal,
    },
    {
        id: 3,
        name: 'Mastercard',
        logo: MastercardLogo,
        originalLogo: MastercardLogoOriginal,
    },
    {
        id: 6,
        name: 'SEPA',
        logo: SEPALogo,
        originalLogo: SEPALogoOriginal,
    },
    {
        id: 7,
        name: 'Sofort',
        logo: SofortLogo,
        originalLogo: SofortLogoOriginal,
    },
    {
        id: 5,
        name: 'Paypal',
        logo: PaypalLogo,
        originalLogo: PaypalLogoOriginal,
    },
    {
        id: 1,
        name: 'ApplePay',
        logo: ApplePayLogo,
        originalLogo: ApplePayLogoOriginal,
    },
    {
        id: 4,
        name: 'Klarna',
        logo: KlarnaLogo,
        originalLogo: KlarnaLogoOriginal,
    },
    {
        id: 2,
        name: 'Giropay',
        logo: GiropayLogo,
        originalLogo: GiropayLogoOriginal,
    },
];
