import { type CSSProperties, memo, useMemo } from 'react';
import { Parallax, ParallaxProvider, type EffectString } from 'react-scroll-parallax';

import { paymentMethods } from '@data/payment-methods.ts';
import styles from './PaymentLogos.module.scss';

const offsets = [-10, 10, -10, 10, -20, 16, -20, 16];

const offsetA = -20;
const offsetB = 20;
const unit = '%';

const PaymentLogos = () => {
    return (
        <ParallaxProvider>
            <div className={styles.paymentLogosContainer}>
                {paymentMethods.map((item, i) => (
                    <PaymentLogoItem key={item.id} index={i} {...item} />
                ))}
            </div>
        </ParallaxProvider>
    );
};

const PaymentLogoItem = memo(
    ({ index: i, logo, originalLogo }: (typeof paymentMethods)[0] & { index: number }) => {
        const { translateY, cardStyle } = useMemo(
            () => ({
                translateY: [
                    `${offsetA * (i % 2 === 0 ? -1 : 1) + offsets[i]}${unit}`,
                    `${offsetB * (i % 2 === 0 ? -1 : 1) + offsets[i]}${unit}`,
                ] as EffectString,
                cardStyle: {
                    '--logo': `url(${logo.src})`,
                    '--original-logo': `url(${originalLogo.src})`,
                } as CSSProperties,
            }),
            [i, logo, originalLogo],
        );

        return (
            <Parallax
                className={styles.paymentLogoCard}
                style={cardStyle}
                translateY={translateY}
            />
        );
    },
);

export default memo(PaymentLogos);
